.icon-upload:before {
  content: 'A';
  font-family: Glyphter !important;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dxi-set,
.dxi:before {
  display: block;
  font: normal normal normal 24px/1 DHX Icons;
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.dxi-chevron-down:before {
  content: '\F140';
}
.dxi-chevron-up:before {
  content: '\F143';
}
.dxi-filter-variant:before {
  content: '\F236';
}
.dxi-close:before {
  content: '\F156';
}
.dxi-unfold-more-horizontal:before {
  content: '\F54F';
}
.dxi-check:before {
  content: '\F12C';
}
.dxi-plus:before {
  content: '\F415';
}
.dxi-delete-forever:before {
  content: '\F5E8';
}
.dxi-download:before {
  content: '\F1DA';
}
.dxi-alert-circle:before {
  content: '\F028';
}
.dxi-checkbox-marked-circle:before {
  content: '\F133';
}
.dxi-undo:before {
  content: '\F54C';
}
.dxi-redo:before {
  content: '\F44E';
}
.dxi-format-align-center:before {
  content: '\F260';
}
.dxi-format-align-left:before {
  content: '\F262';
}
.dxi-format-align-right:before {
  content: '\F263';
}
.dxi-format-bold:before {
  content: '\F264';
}
.dxi-format-italic:before {
  content: '\F277';
}
.dxi-format-underline:before {
  content: '\F287';
}
.dxi-key:before {
  content: '\F306';
}
.dxi-eraser:before {
  content: '\F1FE';
}
.dxi-table-column:before {
  content: '\F834';
}
.dxi-table-column-plus-before:before {
  content: '\F4ED';
}
.dxi-table-column-remove:before {
  content: '\F4EE';
}
.dxi-table-row:before {
  content: '\F836';
}
.dxi-table-row-plus-after:before {
  content: '\F4F3';
}
.dxi-table-row-remove:before {
  content: '\F4F5';
}
.dxi-information-variant:before {
  content: '\F64E';
}
.dxi-menu-down:before {
  content: '\F35D';
}
.dxi-help-circle-outline:before {
  content: '\F625';
}
.dxi-numeric:before {
  content: '\F3A0';
}
.dxi-file-excel:before {
  content: '\F21B';
}
.dxi-file-import:before {
  content: '\F220';
}
.dxi-file-export:before {
  content: '\F21D';
}
.dxi-format-color-fill:before {
  content: '\F266';
}
.dxi-format-color-text:before {
  content: '\F69D';
}
.dxi-close-circle:before {
  content: '\F159';
}
.dxi-menu-right:before {
  content: '\F35F';
}
.dxi-minus:before {
  content: '\F374';
}
.dxi-eye:before {
  content: '\F208';
}
.dxi-eye-off:before {
  content: '\F209';
}
.dxi-rotate-right:before {
  content: '\F467';
}
.dxi-format-vertical-align-bottom:before {
  content: '\F621';
}
.dxi-format-vertical-align-center:before {
  content: '\F622';
}
.dxi-format-vertical-align-top:before {
  content: '\F623';
}
.dxi-format-line-spacing:before {
  content: '\F278';
}
.dxi-printer:before {
  content: '\F42A';
}
.dxi-link-variant:before {
  content: '\F339';
}
.dxi-format-align-justify:before {
  content: '\F261';
}
.dxi-format-strikethrough:before {
  content: '\F280';
}
.dxi-format-quote-close:before {
  content: '\F27E';
}
.dxi-format-size:before {
  content: '\F27F';
}
.dxi-pencil:before {
  content: '\F3EB';
}
.dxi-link-variant-off:before {
  content: '\F33A';
}
.dxi-blank:before {
  content: '\F68C';
  visibility: hidden;
}
.dxi-18px.dxi-set,
.dxi-18px.dxi:before {
  font-size: 18px;
}
.dxi-24px.dxi-set,
.dxi-24px.dxi:before {
  font-size: 24px;
}
.dxi-36px.dxi-set,
.dxi-36px.dxi:before {
  font-size: 36px;
}
.dxi-48px.dxi-set,
.dxi-48px.dxi:before {
  font-size: 48px;
}
.dxi-dark:before {
  color: rgba(0, 0, 0, 0.54);
}
.dxi-dark.mdi-inactive:before {
  color: rgba(0, 0, 0, 0.26);
}
.dxi-light:before {
  color: #fff;
}
.dxi-light.mdi-inactive:before {
  color: hsla(0, 0%, 100%, 0.3);
}
.dxi-rotate-45:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.dxi-rotate-90:before {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.dxi-rotate-135:before {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.dxi-rotate-180:before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.dxi-rotate-225:before {
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.dxi-rotate-270:before {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.dxi-rotate-315:before {
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}
.dxi-flip-h:before {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-filter: FlipH;
  filter: FlipH;
  -ms-filter: 'FlipH';
}
.dxi-flip-v:before {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  -webkit-filter: FlipV;
  filter: FlipV;
  -ms-filter: 'FlipV';
}
.dxi-spin:before {
  -webkit-animation: a 2s linear infinite;
  animation: a 2s linear infinite;
}
@-webkit-keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.dhx_btn {
  box-sizing: border-box;
  border: none;
  outline: none;
  padding: 6px 16px;
  border-radius: 2px;
  font-family: Roboto, Arial, Tahoma, Verdana, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dhx_btn--flat {
  background: #0288d1;
  color: #fff;
}
.dhx_btn--flat:hover {
  color: #fff;
  background: #0278b8;
  cursor: pointer;
}
.dhx_btn--flat:active {
  background-color: #02679e;
}
.dhx_btn--flat.dhx_btn .dhx-icon-block {
  color: #fff;
}
.dhx_btn--flat.dhx_btn--secondary {
  color: #fff;
  background-color: #4c4c4c;
}
.dhx_btn--flat.dhx_btn--secondary:focus,
.dhx_btn--flat.dhx_btn--secondary:hover {
  background-color: #3f3f3f;
}
.dhx_btn--flat.dhx_btn--secondary:active {
  background-color: #333;
}
.dhx_btn--flat.dhx_btn--danger {
  color: #fff;
  background-color: #ff5252;
}
.dhx_btn--flat.dhx_btn--danger:hover {
  background-color: #ff3939;
}
.dhx_btn--flat.dhx_btn--danger:active {
  background-color: #ff1f1f;
}
.dhx_btn--flat.dhx_btn--success {
  color: #fff;
  background-color: #0ab169;
}
.dhx_btn--flat.dhx_btn--success:hover {
  background-color: #09995b;
}
.dhx_btn--flat.dhx_btn--success:active {
  background-color: #07814c;
}
.dhx_btn--link {
  border: none;
  background: transparent;
  color: #015785;
}
.dhx_btn--link:hover {
  background: rgba(2, 136, 209, 0.2);
  color: #015785;
  cursor: pointer;
}
.dhx_btn--link:active {
  background-color: rgba(2, 103, 158, 0.2);
}
.dhx_btn--link.dhx_btn--secondary {
  color: rgba(0, 0, 0, 0.7);
}
.dhx_btn--link.dhx_btn--secondary:active,
.dhx_btn--link.dhx_btn--secondary:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.dhx_btn--link.dhx_btn--danger {
  color: #ff5252;
}
.dhx_btn--link.dhx_btn--danger:hover {
  background-color: rgba(255, 82, 82, 0.15);
}
.dhx_btn--link.dhx_btn--danger:active {
  background-color: rgba(255, 31, 31, 0.15);
}
.dhx_btn--link.dhx_btn--success {
  color: #0ab169;
}
.dhx_btn--link.dhx_btn--success:hover {
  background-color: rgba(10, 177, 105, 0.15);
}
.dhx_btn--link.dhx_btn--success:active {
  background-color: rgba(7, 129, 76, 0.15);
}
.dhx_btn--small {
  padding: 4px 8px !important;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
}
.dhx_btn .dxi {
  font-size: 20px;
  margin-right: 4px;
  margin-left: -4px;
}
.icon-btn {
  position: relative;
  cursor: pointer;
}
.icon-btn:active:after {
  background-color: rgba(0, 0, 0, 0.14);
}
.icon-btn:hover:after {
  opacity: 1;
}
.icon-btn:after {
  content: '';
  opacity: 0;
  width: 32px;
  height: 32px;
  position: absolute;
  top: -6px;
  left: -6px;
  z-index: 2;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.07);
  cursor: pointer;
  z-index: 3;
  -webkit-transition: opacity 0.1s;
  transition: opacity 0.1s;
}
.icon-btn:before {
  position: absolute;
  z-index: 9999;
  display: block;
  width: 20px;
  height: 20px;
}
.dhx_widget {
  font-family: Roboto, Arial, Tahoma, Verdana, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal;
}
.dhx_widget,
.dhx_widget * {
  box-sizing: border-box;
}
.dhx_widget button {
  font-weight: 500;
}
.dhx_widget .dxi,
.dhx_widget .fa,
.dhx_widget .fas,
.dhx_widget .mdi {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: rgba(0, 0, 0, 0.54);
  line-height: 20px;
}
.dhx_widget .dxi:before,
.dhx_widget .fa:before,
.dhx_widget .fas:before,
.dhx_widget .mdi:before {
  font-size: 100%;
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.dhx_widget .dhx-icon-block {
  font-size: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
  position: relative;
  z-index: 99;
}
.dhx_widget .dhx-icon-block:before {
  display: block;
}
.dhx_noselect {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.dhx_widget input:focus::-webkit-input-placeholder {
  color: transparent;
}
.dhx_widget input:focus:-moz-placeholder,
.dhx_widget input:focus::-moz-placeholder {
  color: transparent;
}
.dhx_widget input:focus:-ms-input-placeholder {
  color: transparent;
}
.dhx-webkit-scroll {
  overflow: auto;
}
.dhx-webkit-scroll::-webkit-scrollbar {
  width: 6px;
  height: 25px;
  background-color: transparent;
  border-left: 1px solid #eee;
}
.dhx-webkit-scroll::-webkit-scrollbar-thumb {
  height: 16px;
  width: 6px;
  border: 0 solid transparent;
  background-clip: padding-box;
  border-radius: 17px;
  background-color: #c1c1c1;
}
.dhx-webkit-scroll::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
.dhx-webkit-scroll::-webkit-scrollbar-corner {
  background-color: transparent;
}
.dhx_footer_wrapper,
.dhx_header_wrapper {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  z-index: 50000;
  overflow: hidden;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.38);
  background: #fff;
}
.dhx_footer_wrapper.dhx_compatible_header,
.dhx_header_wrapper.dhx_compatible_header {
  border-right: none;
}
.dhx_footer_wrapper:last-child,
.dhx_header_wrapper:last-child {
  border-bottom: none;
}
.dhx_grid_header {
  z-index: 50;
  border-bottom: 1px solid #f2f2f2;
  background: #fff;
}
.dhx_grid_footer .dhx_grid_footer_cell,
.dhx_grid_header,
.dhx_grid_header_cell {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  white-space: nowrap;
}
.dhx_grid_footer .dhx_grid_footer_cell,
.dhx_grid_header_cell {
  font-family: Roboto, Arial, Tahoma, Verdana, sans-serif;
  font-weight: 400;
  display: inline-block;
  overflow: hidden;
  padding: 0 8px;
  vertical-align: top;
  font-weight: 500;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-top: 1px solid #ededed;
  border-left: none;
}
.dhx_grid_footer .dhx_grid_footer_cell:last-child,
.dhx_grid_header_cell:last-child {
  border-right: 1px solid #ededed;
}
.dhx_grid_footer .dhx_grid_footer_cell:not(.dhx_cell_first),
.dhx_grid_header_cell:not(.dhx_cell_first) {
  border-left: 1px solid #ededed;
}
.dhx_grid_footer .dhx_grid_footer_cell:first-child,
.dhx_grid_header_cell:first-child {
  border-left: none;
}
.dhx_cell_empty {
  border-top: none;
}
.dhx_header_row {
  box-sizing: border-box;
  white-space: nowrap;
  border-right: 1px solid #ededed;
}
.dhx_grid_footer .dhx_header_row:first-child .dhx_grid_footer_cell,
.dhx_header_row:first-child .dhx_grid_footer .dhx_grid_footer_cell,
.dhx_header_row:first-child .dhx_grid_header_cell {
  border-top: none;
}
.dhx_header_row:last-child {
  border-bottom: none;
}
.dhx_grid_footer .dhx_header_row:last-child .dhx_grid_footer_cell,
.dhx_header_row:last-child .dhx_cell_first,
.dhx_header_row:last-child .dhx_grid_footer .dhx_grid_footer_cell,
.dhx_header_row:last-child .dhx_grid_header_cell {
  border-bottom: 1px solid #ededed;
}
.dhx_header_spans {
  position: absolute;
  top: 0;
}
.dhx_span_row:only-child .dhx_span_cell {
  border-bottom: 1px solid #ededed;
}
.dhx_span_row:only-child .dhx_span_cell:only-child {
  border-right: none;
}
.dhx_span_row:last-child {
  border-bottom: 1px solid #ededed;
}
.dhx_grid_filter {
  height: 24px;
  padding: 0 4px;
  color: rgba(0, 0, 0, 0.38);
  border: 1px solid #ededed;
  border-radius: 2px;
  outline: none;
}
.dhx_header_fixed_cols {
  background: #fff;
}
.dhx_grid_footer {
  position: relative;
  background: #fff;
  border-top: 1px solid #ededed;
}
.dhx_grid_footer .dhx_grid_footer_cell {
  background: #f9f9f9;
  border-top: none;
  border-bottom: 1px solid #ededed;
}
.dhx_grid_footer .dhx_grid_footer_cell:after {
  display: none;
}
.dhx_grid_footer .dhx_grid_footer_cell.dhx_number_cell {
  text-align: right;
}
.dhx_grid_footer .dhx_grid_footer_cell.dhx_string_cell {
  text-align: left;
}
.dhx_footer_row {
  box-sizing: border-box;
  white-space: nowrap;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}
.dhx_footer_row:first-child .dhx_grid_footer_cell {
  border-top: none;
}
.dhx_footer_row:last-child {
  border-bottom: none;
}
.dhx_footer_row:last-child .dhx_cell_first,
.dhx_footer_row:last-child .dhx_grid_footer_cell {
  border-bottom: 1px solid #ededed;
}
.dhx_footer_spans .dhx_span_cell {
  background: #f9f9f9;
}
.dhx_footer_fixed_cols,
.dhx_grid_row {
  background: #fff;
}
.dhx_grid_row {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  border-right: 1px solid #ededed;
}
.dhx_grid_row:first-child .dhx_grid_cell {
  border-top: none;
}
.dhx_grid_row:last-child {
  border-bottom: 1px solid #ededed;
}
.dhx_grid_cell {
  font-family: Roboto, Arial, Tahoma, Verdana, sans-serif;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  padding: 0 8px;
  vertical-align: top;
  white-space: nowrap;
  border-top: 1px solid #ededed;
  border-left: 1px solid #ededed;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  text-align: right;
  overflow: hidden;
}
.dhx_grid_cell:last-child {
  border-right: 1px solid #ededed;
}
.dhx_grid_cell .dhx_cell_content {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.dhx_grid_cell:first-child {
  border-left: none;
}
.dhx_grid_cell--first {
  border-top: none;
}
.dhx_grid_cell--title {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  text-align: left;
  border-bottom: none;
}
.dhx_grid_cell--after-title,
.dhx_grid_cell--title {
  border-top: 1px solid #ededed;
}
.dhx_number_cell {
  text-align: right;
}
.dhx_string_cell,
.dhx_tree_cell {
  text-align: left;
}
.dhx_tree_cell {
  color: rgba(0, 0, 0, 0.38);
  font-weight: 500;
}
.dhx_tree_cell .dhx_grid_expand_cell_icon {
  position: absolute;
  top: 0;
  cursor: pointer;
  width: 20px;
  font-size: 20px;
  padding: 0 0 0 4px;
}
.dhx_tree_cell .dhx_tree_cell {
  padding: 0 8px;
  display: inline-block;
}
.dhx_grid_expand_cell {
  cursor: pointer;
}
.dhx_grid_expand_cell .dhx_tree_cell {
  padding: 0 10px 0 30px;
}
.dhx_grid_fixed_cols_wrap {
  position: absolute;
  top: 0;
  left: 0;
  overflow: 'hidden';
}
.dhx_grid_fixed_cols {
  position: 'absolute';
  top: 0;
}
.dhx_grid_selection {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.dhx_span_cell {
  font-family: Roboto, Arial, Tahoma, Verdana, sans-serif;
  font-weight: 400;
  position: relative;
  display: inline-block;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  height: 100%;
  padding: 0 8px;
  vertical-align: top;
  white-space: nowrap;
  border-left: 1px solid #ededed;
  font-weight: 500;
  position: absolute;
  z-index: 99;
  display: block;
  box-sizing: border-box;
  height: auto;
  background: #fff;
  border-bottom: none;
  border-top: 1px solid #ededed;
}
.dhx_span_cell--title {
  color: rgba(0, 0, 0, 0.38);
}
.dhx_span_cell:last-child {
  border-right: 1px solid #ededed;
}
.dhx_span_row:first-child .dhx_span_cell {
  border-top: none;
}
.dhx_grid_spans {
  position: absolute;
  top: 0;
  left: 0;
}
.dhx_grid_spans .dhx_span_cell {
  font-weight: 400;
  line-height: normal;
  text-align: right;
  white-space: normal;
  color: rgba(0, 0, 0, 0.7);
  background: #fff;
}
.dhx_grid_spans .dhx_span_cell:last-child {
  border-right: none;
}
.dhx_grid_spans .dhx_span_cell.dhx_span_first_row {
  border-top: none;
}
.dhx_grid_spans .dhx_span_cell.dhx_span_first_col {
  border-left: none;
}
.dhx_grid_spans .dhx_span_cell.dhx_span_last_col {
  border-right: 1px solid #ededed;
}
.dhx_grid {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: #fff;
}
.dhx_grid,
.dhx_grid * {
  box-sizing: border-box;
}
.dhx_grid_content {
  position: relative;
  border: 1px solid #d9d9d9;
  background: #fff;
}
.dhx_grid_body {
  overflow: auto;
}
.dhx_data_wrap,
.dhx_grid_body {
  position: relative;
  width: 100%;
  height: 100%;
}
.dhx_data_wrap {
  overflow: hidden;
}
.dhx_sort--asc:after {
  border-bottom: 5px solid rgba(0, 0, 0, 0.38);
}
.dhx_sort--asc:after,
.dhx_sort--desc:after {
  content: '';
  position: absolute;
  top: 45%;
  right: 10%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.dhx_sort--desc:after {
  border-top: 5px solid rgba(0, 0, 0, 0.38);
}
.toolbar {
  height: 56px;
  padding: 0 12px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
}
.toolbar .separator {
  height: 50%;
  width: 1px;
  background: #dfdfdf;
  position: relative;
  margin: 0 2px;
}
.toolbar .icon-btn {
  position: relative;
  cursor: pointer;
  margin: 0 10px;
}
.toolbar .icon-btn.active,
.toolbar .icon-btn.active:hover {
  background-color: transparent;
}
.toolbar .icon-btn.active:before {
  display: block;
  background: rgba(0, 0, 0, 0.15);
}
.toolbar .icon-btn.active:hover:after {
  display: none;
}
.toolbar .icon-btn .dhx-icon-block {
  z-index: 99;
  position: relative;
  cursor: pointer;
}
.toolbar .icon-btn:first-child {
  margin: 0 10px 0 0;
}
.toolbar .icon-btn:last-child {
  margin: 0 0 0 10px;
}
.toolbar .icon-btn .counter {
  top: -12px;
  right: -6px;
}
.toolbar .menu-item {
  border-radius: 2px;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  box-sizing: border-box;
  font-weight: 500;
  line-height: 20px;
  padding: 8px 12px;
  cursor: pointer;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.toolbar .menu-item:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.toolbar .menu-item:active {
  background-color: #d4d4d4;
}
.toolbar .menu-item .dhx-icon-block {
  margin: 0 12px 0 -4px;
}
.toolbar .menu-item > .dhx-icon.sub-menu-opener {
  margin: 0 -4px 0 4px;
}
.toolbar .menu-item > .dhx-icon.sub-menu-opener .dhx-icon-block {
  margin: 0;
}
.toolbar .text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 12px;
  cursor: default;
}
.toolbar .spacer {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.toolbar .img-btn {
  padding: 0 12px;
}
.toolbar .img-btn:hover {
  background-color: inherit;
}
.toolbar .img-btn .img-button-wrapper {
  height: 45px;
  width: 45px;
  position: relative;
}
.toolbar .img-btn .img-button-wrapper:hover:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 100%, 0.4);
}
.toolbar .img-btn .img-button-wrapper .img-button {
  width: 100%;
  height: 100%;
}
.toolbar .img-btn .counter {
  right: 8px;
}
.toolbar .counter {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fe5e39;
  color: #fff;
  top: 4px;
  right: 4px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  border-radius: 50%;
  z-index: 199;
  cursor: pointer;
}
.toolbar .input-container {
  margin: 0 0 0 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.toolbar .input-container .dxi-icon {
  font-size: 20px;
  position: absolute;
  right: 4px;
  color: #979797;
  z-index: 4;
}
.toolbar .input-container .input-wrapper {
  position: relative;
  z-index: 2;
}
.toolbar .input-container .input-wrapper .input-animation {
  z-index: 3;
  content: '';
  display: block;
  height: 1px;
  width: 10px;
  background-color: #0288d1;
  position: absolute;
  bottom: 0;
  left: 50%;
  opacity: 0;
  -webkit-transition: all 0.9s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.9s cubic-bezier(0.19, 1, 0.22, 1);
}
.toolbar .input-container input.text-input {
  outline: none;
  border: none;
  border-bottom: 1px solid #dedede;
  height: 32px;
  padding: 0 32px 0 8px;
  line-height: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}
.toolbar .input-container input.text-input:focus ~ .input-animation {
  width: 100%;
  left: 0;
  opacity: 1;
  -webkit-transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
}
.toolbar .input-container input.text-input:hover {
  cursor: pointer;
}
.toolbar .dhx_btn {
  -ms-flex-item-align: center;
  align-self: center;
  margin: 0 4px;
}
.toolbar .dhx_btn_small {
  padding: 4px 8px !important;
}
.toolbar .icon-menu-item {
  padding: 0;
  -ms-flex-item-align: center;
  align-self: center;
  position: relative;
}
.toolbar .icon-menu-item:hover {
  background-color: unset;
}
.toolbar .icon-menu-item:hover:before {
  display: block;
}
.toolbar .icon-menu-item:before {
  content: '';
  display: none;
  width: 32px;
  height: 32px;
  position: absolute;
  background-color: #e5e5e5;
  z-index: 2;
  top: -4px;
  left: -4px;
  border-radius: 50%;
}
.toolbar .icon-menu-item .dhx-icon {
  margin: 0;
  z-index: 4;
}
.toolbar .icon-menu-item .dhx-icon .dhx-icon-block {
  font-size: 24px;
}
.toolbar .icon-menu-item .dhx-icon.sub-menu-opener {
  display: none;
}
.toolbar .active,
.toolbar .active:hover {
  background: rgba(0, 0, 0, 0.15);
}
.menu-popups .dhx_widget.menu-popup {
  z-index: 6 !important;
}
.button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px 12px;
  position: relative;
  font-weight: 500;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
.button-container.no-text .dhx-icon-block {
  margin: 0;
}
.button-container:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 2px;
}
.button-container:active {
  background-color: #d4d4d4;
}
.button-container .dhx-icon-block {
  margin: 0 8px 0 -4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ripple-container-outside {
  position: absolute;
  left: -15px;
  top: -15px;
  width: calc(100% + 30px);
  height: calc(100% + 30px);
  pointer-events: none;
}
.ripple-animation {
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-animation: b 0.35s linear;
  animation: b 0.35s linear;
}
@-webkit-keyframes b {
  to {
    opacity: 0;
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
  }
}
@keyframes b {
  to {
    opacity: 0;
    -webkit-transform: scale(2.5);
    transform: scale(2.5);
  }
}
#menu {
  display: inline-block;
}
.main-menu {
  background-color: #ededed;
}
.main-menu,
.main-menu .menu-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
}
.main-menu .menu-item {
  font-weight: 500;
  line-height: 20px;
  padding: 8px 12px;
  cursor: pointer;
  text-align: center;
}
.main-menu .menu-item:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.main-menu .separator {
  width: 1px;
  background: #dfdfdf;
  height: 20px;
  position: relative;
  margin: 0 2px;
}
.main-menu .dhx-icon-block {
  display: block;
  text-align: center;
  margin: 0 12px 0 -4px;
  line-height: 1;
  font-size: 20px;
  color: #757575;
}
.main-menu .dhx-icon-block:before {
  display: block;
}
.menu-popup {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
}
.menu-popup .menu-item {
  background-color: #fff;
  position: relative;
  padding: 8px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.menu-popup .menu-item .menu-item-content {
  margin: 0 0 0 8px;
  line-height: 20px;
  display: inline-block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.menu-popup .menu-item .hotkey {
  margin: 0 0 0 8px;
  color: rgba(0, 0, 0, 0.38);
  line-height: 20px;
}
.menu-popup .menu-item.active,
.menu-popup .menu-item:hover {
  background-color: #ededed;
}
.menu-popup .menu-item.active:before {
  content: '';
  display: block;
  width: 2px;
  height: 100%;
  background: #0288d1;
  position: absolute;
  left: 0;
  top: 0;
}
.menu-popup .dhx-icon {
  display: block;
  text-align: center;
}
.menu-popup .dhx-icon .dhx-icon-block {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #757575;
}
.menu-popup .dhx-icon .dhx-icon-block:before {
  display: block;
}
.menu-popup .separator {
  width: calc(100% - 16px);
  margin: 2px auto;
  height: 1px;
  background: #dfdfdf;
}
.dhx-icon.sub-menu-opener {
  margin: 0 0 0 12px;
}
.dhx-icon.sub-menu-opener > .dxi {
  width: 20px;
  height: 20px;
}
.menu-item.disabled {
  cursor: not-allowed;
}
.menu-item.disabled:hover {
  background-color: transparent;
}
.menu-item.disabled .dhx-icon .dhx-icon-block,
.menu-item.disabled .hotkey,
.menu-item.disabled .menu-item-content {
  color: rgba(0, 0, 0, 0.2);
}
.icon-none:before {
  color: transparent !important;
}
.main-menu .dhx_btn {
  margin: 0 4px;
}
.dhx-message-container {
  position: fixed;
  z-index: 11100;
  top: 20px;
  right: 20px;
}
.dhx-message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-width: 230px;
  padding: 12px 8px 12px 12px;
  margin: 0 0 8px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1), 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: Roboto, Arial, Tahoma, Verdana, sans-serif;
}
.dhx-message > .dxi {
  font-size: 20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.dhx-message.dhx-error {
  background-color: #ff5252;
  color: #fff;
}
.dhx-blocker {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.8;
  z-index: 11110;
}
.dhx-confirm {
  min-height: 220px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  width: 650px;
  position: relative;
  margin: 0 auto;
  padding: 28px;
  z-index: 11111;
}
.dhx-confirm .message-box-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 20px;
}
.dhx-confirm .message-box-header > .mesage-box-title {
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  padding: 0 8px 0 0;
  word-break: break-all;
}
.dhx-confirm .message-box-header > .mesage-box-action {
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}
.dhx-confirm .message-box-header > .mesage-box-action > .dxi {
  font-size: 20px;
}
.dhx-confirm .confirm-message {
  margin: 0 0 28px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  line-height: 20px;
  max-height: 60px;
  overflow-y: auto;
}
.dhx-confirm .action-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.dhx-confirm .action-button .apply-button,
.dhx-confirm .action-button .reject-button {
  padding: 8px 16px;
}
.dhx-confirm .action-button .dhx_btn:last-child {
  margin: 0 0 0 16px;
}
.dhx-alert {
  min-height: 220px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  width: 650px;
  position: relative;
  margin: 0 auto;
  padding: 28px;
  z-index: 11111;
}
.dhx-alert .message-box-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 0 20px;
}
.dhx-alert .message-box-header > .mesage-box-title {
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.dhx-alert .message-box-header > .mesage-box-action {
  height: 20px;
  width: 20px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 20px;
  cursor: pointer;
}
.dhx-alert > .alert-message {
  margin: 0 0 28px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  line-height: 20px;
  max-height: 60px;
  overflow-y: auto;
}
.dhx-alert .action-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.dhx-alert .action-button .dhx_btn:last-child {
  margin: 0 0 0 16px;
}
.dhx-alert .action-button .alert-btn {
  padding: 8px 16px;
}
.dhx-error {
  opacity: 1;
  -webkit-transition: all 1.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 1.5s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.dhx-confirm.btn-center-aligment .action-button {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.dhx-confirm.btn-left-aligment .action-button {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.dhx-tooltip {
  pointer-events: none;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  padding: 4px 8px;
  -webkit-transition: opacity transform 0.01s ease;
  transition: opacity transform 0.01s ease;
  opacity: 0;
  z-index: 9999999;
  font-family: Roboto, Arial, Tahoma, Verdana, sans-serif;
}
.dhx-tooltip .tooltip-text {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.dhx-tooltip.botoom {
  margin: 8px 0 0;
}
.dhx-tooltip.top {
  margin: -8px 0 0;
}
.dhx-tooltip.left {
  margin: 0 0 0 -8px;
}
.dhx-tooltip.right {
  margin: 0 0 0 8px;
}
.animate-tooltip {
  opacity: 1;
  -webkit-transition: opacity 0.15s ease-out;
  transition: opacity 0.15s ease-out;
}
.forced {
  opacity: 1;
}
.dhx-tooltip.tooltip-light {
  pointer-events: none;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  padding: 4px 8px;
  -webkit-transition: opacity transform 0.01s ease;
  transition: opacity transform 0.01s ease;
  z-index: 9999999;
  font-family: Roboto, Arial, Tahoma, Verdana, sans-serif;
}
.dhx-tooltip.tooltip-light .tooltip-text {
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.ribbon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  background-color: #f7f7f7;
  padding: 12px 12px 8px 8px;
  box-sizing: border-box;
}
.ribbon .dhx_btn {
  margin: 0 4px 4px;
}
.ribbon > .ribbon-item-block {
  margin: 0 8px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
.ribbon .block-label {
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  margin: 6px 0 0;
  position: relative;
}
.ribbon .block-label:before {
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.07);
  position: absolute;
  bottom: 7px;
  left: 0;
  z-index: 3;
}
.ribbon .block-label span {
  background-color: #f7f7f7;
  z-index: 5;
  position: relative;
  padding: 0 8px;
}
.ribbon .element {
  border: 1px solid #dfdfdf;
  margin: 0 0 4px 4px;
  padding: 5px 12px;
  box-sizing: border-box;
}
.ribbon .element .dxi {
  font-size: 20px;
}
.ribbon .element .button-text {
  font-size: 14px;
  line-height: 20px;
}
.ribbon .icon-menu-item {
  cursor: pointer;
}
.ribbon .icon-menu-item:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.ribbon .icon-menu-item .dhx-icon.sub-menu-opener {
  display: none;
}
.ribbon .button-container.img-text-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 94px;
  padding: 5px 12px;
}
.ribbon .button-container.img-text-btn img {
  max-width: 20px;
  max-height: 20px;
  margin: 0 8px 0 0;
}
.ribbon .button-container.img-text-btn.big {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-width: 68px;
  min-height: 68px;
  padding: 4px 0 2px;
}
.ribbon .button-container.img-text-btn.big img {
  max-width: 40px;
  max-height: 40px;
  margin: 0;
}
.ribbon .img-btn {
  padding: 12px;
}
.ribbon .img-btn:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.ribbon .img-btn .img-button-wrapper {
  height: 42px;
  width: 42px;
}
.ribbon .img-btn .img-button-wrapper .img-button {
  width: 100%;
  height: 100%;
}
.ribbon .img-btn .counter {
  right: 8px;
  top: 8px;
}
.ribbon .counter {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fe5e39;
  color: #fff;
  top: 4px;
  right: 4px;
  font-size: 12px;
  border-radius: 50%;
  z-index: 5;
}
.ribbon .counter,
.ribbon .menu-item {
  line-height: 20px;
  font-weight: 500;
  text-align: center;
}
.ribbon .menu-item {
  box-sizing: border-box;
  padding: 5px 8px;
  min-width: 92px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.ribbon .menu-item:hover {
  background-color: rgba(0, 0, 0, 0.07);
}
.ribbon .menu-item .dhx-icon {
  margin: 0 8px 0 0;
  display: inline;
}
.ribbon .menu-item .sub-menu-opener {
  margin: 0 0 0 8px;
}
.ribbon .input-container {
  padding: 2px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f7f7f7;
}
.ribbon .input-container .dxi-icon {
  font-size: 20px;
  position: absolute;
  top: 6px;
  right: 4px;
  color: #979797;
}
.ribbon .input-container input.text-input {
  background-color: #f7f7f7;
  outline: none;
  border: none;
  height: 26px;
  padding: 0 28px 0 8px;
  line-height: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}
.ribbon .input-container input.text-input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.54);
}
.ribbon .dhx_btn {
  padding: 6px 12px;
  border: none;
}
.ribbon .text.element {
  padding: 8px 12px;
}
.ribbon .ribbon-item-block.ribbon-row > .block-content {
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.ribbon .ribbon-item-block.ribbon-cols > .block-content,
.ribbon .ribbon-item-block.ribbon-row > .block-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.ribbon .ribbon-item-block.ribbon-cols > .block-content {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}
.ribbon .button-container.main-btn.element.big {
  -ms-flex-item-align: stretch;
  align-self: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 68px;
  min-height: 68px;
  box-sizing: border-box;
}
.ribbon .button-container.main-btn.element.big .dhx-icon {
  margin: 2px 0 0;
}
.ribbon .button-container.main-btn.element {
  min-width: 94px;
}
.ribbon .button-container.main-btn.element > .dhx-icon {
  margin: 0 8px 0 0;
}
.ribbon .button-container.icon-btn.element .dhx-icon {
  margin: 0;
}
.sidebar {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}
.sidebar,
.sidebar .item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
}
.sidebar .item-wrapper {
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.sidebar .item-wrapper > .dxi {
  width: 34px;
  height: 34px;
  line-height: 34px;
  font-size: 20px;
}
.sidebar .active {
  position: relative;
}
.sidebar .active:before {
  content: '';
  left: 0;
  top: 0;
  position: absolute;
  height: 100%;
  width: 4px;
  background: #00f;
}
.with-childs {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.with-childs,
.with-childs > .item-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.with-childs > .item-wrapper {
  position: relative;
}
.with-childs > .item-wrapper,
.with-childs > .item-wrapper > .element {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.with-childs > .item-wrapper > .dxi {
  position: absolute;
  margin: 10px 4px 0 0;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
}
.dhx_cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.dhx_cell .dhx_cell_content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.dhx_cell.flex,
.dhx_cell.flex > .dhx_cell_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.dhx_cell_content {
  overflow-y: auto;
}
body > .dhx_cell.layout_y {
  height: 100%;
  margin: 0;
  border: none;
}
.dhx_cell.layout_y {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}
.dhx_cell.layout_y > .dhx_cell_content {
  overflow-y: hidden;
}
.dhx_cell.layout_x,
.dhx_cell.layout_y > .dhx_cell_content {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.dhx_cell.layout_x {
  overflow: hidden;
}
.dhx_cell.layout_x > .dhx_cell_content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.dhx_cell {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  font-family: Helvetica, Tahoma;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.dhx_cell,
.dhx_cell_footer,
.dhx_cell_header {
  box-sizing: border-box;
}
.dhx_cell_footer,
.dhx_cell_header {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 12px 8px 12px 12px;
  background: #f7f7f7;
  color: #0288d1;
  min-height: 44px;
  border-bottom: 1px solid #dfdfdf;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
body > .dhx_cell.layout_x,
body > .dhx_cell.layout_y {
  border: none;
}
.content.dhx_cell > .dhx_cell_content {
  padding: 8px;
}
.dhx_cell_content {
  min-height: 25px;
}
.space-around {
  margin: 8px;
}
.no-space {
  margin: 0;
}
.space-horizontal {
  margin-left: 8px;
  margin-right: 8px;
}
.space-vertical {
  margin-top: 8px;
  margin-bottom: 8px;
}
.layout_x > .dhx_cell.space-left,
.layout_y > .dhx_cell_content > .dhx_cell.space-left {
  margin-left: 8px;
}
.layout_x > .dhx_cell.space-left-none,
.layout_y > .dhx_cell_content > .dhx_cell.space-left-none {
  margin-left: 0;
}
.layout_x > .dhx_cell.space-right,
.layout_y > .dhx_cell_content > .dhx_cell.space-right {
  margin-right: 8px;
}
.layout_x > .dhx_cell.space-right-none,
.layout_y > .dhx_cell_content > .dhx_cell.space-right-none {
  margin-right: 0;
}
.layout_x > .dhx_cell.space-top,
.layout_y > .dhx_cell_content > .dhx_cell.space-top {
  margin-top: 8px;
}
.layout_x > .dhx_cell.space-top-none,
.layout_y > .dhx_cell_content > .dhx_cell.space-top-none {
  margin-top: 0;
}
.layout_x > .dhx_cell.space-bottom,
.layout_y > .dhx_cell_content > .dhx_cell.space-bottom {
  margin-bottom: 8px;
}
.layout_x > .dhx_cell.space-bottom-none,
.layout_y > .dhx_cell_content > .dhx_cell.space-bottom-none {
  margin-bottom: 0;
}
.border-all {
  border: 1px solid #dfdfdf;
}
.border-none {
  border: none;
}
.layout_x > .dhx_cell.border-left,
.layout_y > .dhx_cell_content > .dhx_cell.border-left {
  border-left: 1px solid #dfdfdf;
}
.layout_x > .dhx_cell.border-left-none,
.layout_y > .dhx_cell_content > .dhx_cell.border-left-none {
  border-left: none;
}
.layout_x > .dhx_cell.border-right,
.layout_y > .dhx_cell_content > .dhx_cell.border-right {
  border-right: 1px solid #dfdfdf;
}
.layout_x > .dhx_cell.border-right-none,
.layout_y > .dhx_cell_content > .dhx_cell.border-right-none {
  border-right: none;
}
.layout_x > .dhx_cell.border-top,
.layout_y > .dhx_cell_content > .dhx_cell.border-top {
  border-top: 1px solid #dfdfdf;
}
.layout_x > .dhx_cell.border-top-none,
.layout_y > .dhx_cell_content > .dhx_cell.border-top-none {
  border-top: none;
}
.layout_x > .dhx_cell.border-bottom,
.layout_y > .dhx_cell_content > .dhx_cell.border-bottom {
  border-bottom: 1px solid #dfdfdf;
}
.layout_x > .dhx_cell.border-bottom-none,
.layout_y > .dhx_cell_content > .dhx_cell.border-bottom-none {
  border-bottom: none;
}
.panel {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #dfdfdf;
  z-index: 99;
}
.panel .dhx_cell_footer,
.panel .dhx_cell_header {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: none;
}
.layout_x > .dhx_cell_content > .panel,
.layout_y > .dhx_cell_content > .panel {
  margin: 8px;
}
.collapsed {
  box-shadow: none;
  color: #000;
  overflow: hidden;
}
.dhx_cell.layout_y > .dhx_cell.collapsed {
  -ms-flex-preferred-size: 44px;
  flex-basis: 44px;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.dhx_cell.layout_y .dhx_cell_content > .dhx_cell.collapsed {
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 44px !important;
  flex: 0 0 44px !important;
}
.dhx_cell.layout_y .dhx_cell_content > .dhx_cell.collapsed > .dhx_cell_footer > .header-action-icon,
.dhx_cell.layout_y .dhx_cell_content > .dhx_cell.collapsed > .dhx_cell_header > .header-action-icon {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  color: #000;
}
.dhx_cell.layout_y .dhx_cell_content > .dhx_cell.collapsed > .dhx_cell_footer .header-text,
.dhx_cell.layout_y .dhx_cell_content > .dhx_cell.collapsed > .dhx_cell_header .header-text {
  color: #000;
}
.dhx_cell.layout_x > .dhx_cell_content > .dhx_cell.collapsed {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 44px !important;
  flex: 0 0 44px !important;
}
.dhx_cell.layout_x > .dhx_cell_content > .dhx_cell.collapsed > .dhx_cell_footer,
.dhx_cell.layout_x > .dhx_cell_content > .dhx_cell.collapsed > .dhx_cell_header {
  padding: 12px;
}
.dhx_cell.layout_x > .dhx_cell_content > .dhx_cell.collapsed > .dhx_cell_footer > .header-action-icon,
.dhx_cell.layout_x > .dhx_cell_content > .dhx_cell.collapsed > .dhx_cell_header > .header-action-icon {
  width: 100%;
}
.dhx_cell.layout_x > .dhx_cell_content > .dhx_cell.collapsed > .dhx_cell_footer > .header-action-icon > .dxi,
.dhx_cell.layout_x > .dhx_cell_content > .dhx_cell.collapsed > .dhx_cell_header > .header-action-icon > .dxi {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #000;
}
.dhx_cell.layout_x > .dhx_cell_content > .dhx_cell.collapsed > .dhx_cell_footer {
  display: none;
}
.dhx_cell.layout_x > .dhx_cell_content > .dhx_cell.collapsed > .dhx_cell_footer,
.dhx_cell.layout_x > .dhx_cell_content > .dhx_cell.collapsed > .dhx_cell_header {
  min-width: 44px;
  box-sizing: border-box;
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  margin: 0;
  border-bottom: none;
  border-left: 1px solid #dfdfdf;
}
.dhx_cell.layout_x > .dhx_cell_content > .dhx_cell.collapsed > .dhx_cell_footer > .header-text,
.dhx_cell.layout_x > .dhx_cell_content > .dhx_cell.collapsed > .dhx_cell_header > .header-text {
  display: block;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #000;
  height: 100%;
  text-align: center;
}
.resizer.x {
  width: 8px;
}
.resizer.x:hover > .dxi {
  opacity: 1;
}
.resizer.x > .dxi {
  position: relative;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
  left: -8px;
  font-size: 24px;
  color: #bbb;
  cursor: e-resize;
  opacity: 0;
}
.resizer.x > .dxi:before {
  width: 24px;
  color: #bbb;
}
.resizer.y {
  width: 100%;
  background: #fff;
  height: 8px;
  z-index: 100;
}
.resizer.y:hover > .dxi {
  opacity: 1;
}
.resizer.y > .dxi {
  display: inline-block;
  position: relative;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  left: 50%;
  top: -8px;
  font-size: 24px;
  color: #bbb;
  cursor: n-resize;
  opacity: 0;
}
.resizer.y > .dxi:before {
  width: 24px;
  color: #bbb;
}
.dhx-no-select {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.dhx_cell.elevation-1 {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}
.dhx_cell_content .panel {
  border: 1px solid #dfdfdf;
}
.dhx_cell.shadow-bottom {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 10;
}
.dhx_cell.line-left {
  border-left: 1px solid #e0e0e0;
}
.layout_x > .user1,
.layout_y > .dhx_cell_content > .user1 {
  margin-right: 25px;
}
.layout_x .user2,
.layout_y > .dhx_cell_content .user2 {
  margin-left: 25px;
}
.dhx_colorpicker_wrap {
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
  padding: 8px;
}
.dhx_colorpicker_wrap,
.dhx_palette {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.dhx_palette {
  margin: 0;
  white-space: nowrap;
}
.dhx_palette_col {
  display: inline-block;
  box-sizing: border-box;
}
.dhx_palette_col:last-child .dhx_palette_cell {
  margin-right: 0;
}
.dhx_palette_row {
  box-sizing: border-box;
}
.dhx_palette_row .dhx_palette_cell {
  display: inline-block;
  margin-bottom: 6px;
}
.dhx_palette_row .dhx_palette_cell:last-child {
  margin-right: 0;
}
.dhx_palette_cell {
  position: relative;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin: 0 2px 2px 0;
  vertical-align: top;
}
.dhx_palette_cell:last-child {
  margin-bottom: 0;
}
.dhx_palette_cell:hover {
  border: 2px solid hsla(0, 0%, 100%, 0.6);
}
.dhx_palette_cell:hover:after {
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  content: '';
  border: 1px solid rgba(0, 0, 0, 0.6);
}
.dhx_palette_cell.dhx_selected {
  border: 2px solid hsla(0, 0%, 100%, 0.6);
}
.dhx_palette_cell.dhx_selected:after {
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  content: '';
  border: 1px solid rgba(0, 0, 0, 0.6);
}
.custom_color_cell {
  font-size: 1.2em;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  color: #fff;
  text-shadow: 0 0 15px #000;
}
.custom_color_cell.dhx_selected,
.custom_color_cell:hover {
  line-height: 16px;
}
.dhx_picker_palette {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 2px;
}
.dhx_picker_palette:before {
  z-index: 2;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#000), to(hsla(0, 0%, 100%, 0)));
  background-image: -webkit-linear-gradient(bottom, #000, hsla(0, 0%, 100%, 0));
  background-image: linear-gradient(0deg, #000, hsla(0, 0%, 100%, 0));
}
.dhx_picker_palette:after,
.dhx_picker_palette:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.dhx_picker_palette:after {
  z-index: 1;
  background-image: -webkit-gradient(linear, left top, right top, from(#fff), to(hsla(20, 42%, 65%, 0)));
  background-image: -webkit-linear-gradient(left, #fff, hsla(20, 42%, 65%, 0));
  background-image: linear-gradient(90deg, #fff, hsla(20, 42%, 65%, 0));
}
.dhx_hue_range {
  position: relative;
  width: 100%;
  margin: 4px 0 8px;
  border-radius: 2px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(red),
    color-stop(17%, #ff0),
    color-stop(33%, #0f0),
    color-stop(50%, #0ff),
    color-stop(67%, #00f),
    color-stop(83%, #f0f),
    to(red)
  );
  background-image: -webkit-linear-gradient(left, red, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red);
  background-image: linear-gradient(90deg, red, #ff0 17%, #0f0 33%, #0ff 50%, #00f 67%, #f0f 83%, red);
}
.dhx_range_grip {
  width: 6px;
  height: 100%;
}
.dhx_palette_grip,
.dhx_range_grip {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #fff;
  background: transparent;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}
.dhx_palette_grip {
  z-index: 10;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.dhx_color_value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 32px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.dhx_color_value .dhx_current_color {
  width: 105px;
  border: 1px solid #d4dde7;
  border-radius: 2px;
}
.dhx_color_value .dhx_hex_input_wrap {
  position: relative;
  border: 1px solid #d4dde7;
}
.dhx_color_value .dhx_hex_input_wrap:before {
  position: absolute;
  top: 5px;
  left: 8px;
  content: '#';
  color: #909cad;
}
.dhx_color_value .dhx_hex_input {
  width: 105px;
  height: 100%;
  padding: 0 4px 0 24px;
  border: none;
  border-radius: 2px;
  outline: none;
}
.dhx_picker_buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 0 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.dhx_picker_buttons .dhx_cancel {
  margin-right: 4px;
  color: #0288d1;
}
.dhx_custom_colors {
  font-size: 14px;
  color: #909cad;
}
.dhx_custom_colors .hint {
  display: none;
}
.dhx_custom_colors:hover .hint {
  display: inline;
}
.dhx_custom_colors .dhx_custom_colors_header {
  font-size: 14px;
  padding: 12px 0 4px;
  letter-spacing: -0.2px;
}
.dhx_custom_colors .dhx_show_picker {
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #909cad;
  box-shadow: inset 0 0 0 1px #909cad;
}
.dhx-popup {
  position: fixed;
  z-index: 999999;
  box-sizing: border-box;
  -webkit-animation: c 0.2s ease-in-out forwards;
  animation: c 0.2s ease-in-out forwards;
  background: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}
.dhx-popup.hide {
  -webkit-animation: d 0.2s ease-in-out forwards;
  animation: d 0.2s ease-in-out forwards;
}
.dhx-popup * {
  box-sizing: border-box;
}
@-webkit-keyframes c {
  0% {
    -webkit-transform: translateY(30%);
    transform: translateY(30%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes c {
  0% {
    -webkit-transform: translateY(30%);
    transform: translateY(30%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes d {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(30%);
    transform: translateY(30%);
    opacity: 0;
  }
}
@keyframes d {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(30%);
    transform: translateY(30%);
    opacity: 0;
  }
}
.dhx-spreadsheet {
  height: 100%;
  border-top: 1px solid #d9d9d9;
}
.dhx-spreadsheet * {
  box-sizing: border-box;
}
.menu_wrapper {
  min-height: 36px;
}
.menu_wrapper .main-menu {
  background-color: #f7f7f7;
}
.dhx-tooltip {
  text-transform: lowercase;
}
.dhx-tooltip:first-letter {
  text-transform: uppercase;
}
.editLine_wrapper {
  min-height: 32px;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}
.dhx_edit_line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 32px;
}
.dhx_edit_line .input-wrapper {
  position: relative;
  width: 100%;
}
.dhx_edit_line .input-wrapper .dhx_edit_line_input {
  font-family: Roboto, Arial, Tahoma, Verdana, sans-serif;
  font-size: 14px;
  width: 100%;
  height: 100%;
  padding: 2px 0 0 12px;
  border: none;
  outline: none;
  background: hsla(0, 0%, 87%, 0);
}
.dhx_edit_line .input-wrapper .dhx_edit_line_input:focus ~ .input-animation {
  left: 0;
  width: 100%;
  -webkit-transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 1;
}
.dhx_edit_line .input-wrapper .input-animation {
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 50%;
  display: block;
  width: 10px;
  height: 1px;
  content: '';
  -webkit-transition: all 0.9s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.9s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  background-color: #0288d1;
}
.fx {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 100%;
  text-align: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.fx .dxi {
  font-size: 20px;
  width: 30px;
  height: 20px;
  color: rgba(0, 0, 0, 0.38);
  border-right: 1px solid rgba(0, 0, 0, 0.07);
}
.dhx_cell_input {
  font-family: Roboto, Arial, Tahoma, Verdana, sans-serif;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  height: 100%;
  padding: 0 8px;
  text-align: left;
  color: rgba(0, 0, 0, 0.7);
  border: none;
  outline: none;
  background: transparent;
  box-shadow: 0 1px 5px 0 rgba(2, 136, 209, 0.3);
}
.dhx-spreadsheet-grid {
  height: 100%;
}
.dhx-spreadsheet-grid .dhx_grid_footer .dhx_grid_footer_cell,
.dhx-spreadsheet-grid .dhx_grid_header_cell,
.dhx_grid_footer .dhx-spreadsheet-grid .dhx_grid_footer_cell {
  text-align: center;
  text-transform: uppercase;
  border-left: none;
  background: #ededed;
}
.dhx-spreadsheet-grid .dhx_grid_fixed_cols_wrap {
  z-index: 20;
}
.dhx-spreadsheet-grid .dhx_grid_cell {
  padding: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-top: 1px solid #dedede;
  border-left: 1px solid #dedede;
}
.dhx-spreadsheet-grid .dhx_grid_cell .dhx_lock_icon {
  font-size: 16px;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 4px;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.3);
}
.dhx-spreadsheet-grid .dhx_grid_row {
  overflow: visible;
}
.dhx-spreadsheet-grid .dhx_grid_footer .dhx_grid_footer_cell,
.dhx-spreadsheet-grid .dhx_grid_header_cell,
.dhx_grid_footer .dhx-spreadsheet-grid .dhx_grid_footer_cell {
  border-bottom: 1px solid #dedede;
}
.dhx-spreadsheet-grid .dhx_grid_footer .dhx_selected_header.dhx_grid_footer_cell,
.dhx-spreadsheet-grid .dhx_grid_header_cell.dhx_selected_header,
.dhx_grid_footer .dhx-spreadsheet-grid .dhx_selected_header.dhx_grid_footer_cell {
  border-left: 1px solid #ccc;
  background: #dcdcdc;
}
.dhx-spreadsheet-grid .dhx_selected_cell {
  box-shadow: inset 0 0 0 2px #0288d1;
}
.dhx-spreadsheet-grid .dhx_spreadsheet_cell {
  overflow: hidden;
  padding: 0 8px;
  text-decoration: inherit;
}
.dhx-spreadsheet-grid .dhx_selection_grip {
  position: absolute;
  z-index: 10;
  right: -6px;
  bottom: -6px;
  width: 12px;
  height: 12px;
  cursor: crosshair;
  border: 2px solid #fff;
  border-radius: 50%;
  background: #0288d1;
}
.dhx-spreadsheet-grid .dhx_group_selection {
  position: absolute;
  pointer-events: none;
  border: 1px solid #0288d1;
  background-color: rgba(2, 136, 209, 0.15);
}
.dhx-spreadsheet-grid .dhx_copy_selection {
  position: absolute;
  z-index: 999;
  pointer-events: none;
  border: none;
  background: -webkit-linear-gradient(left, #0288d1 100%, #fff 0) 0 0 no-repeat,
    -webkit-linear-gradient(left, #fff 1.5px, #0288d1 0, #0288d1 3.5px, #fff 0) top repeat-x,
    -webkit-linear-gradient(left, #0288d1 100%, #fff 0) 100% 0 no-repeat,
    -webkit-linear-gradient(bottom, #0288d1 100%, #fff 0) 0 0 no-repeat,
    -webkit-linear-gradient(bottom, #fff 1.5px, #0288d1 0, #0288d1 3.5px, #fff 0) 0 repeat-y,
    -webkit-linear-gradient(bottom, #0288d1 100%, #fff 0) 0 100% no-repeat,
    -webkit-linear-gradient(left, #0288d1 100%, #fff 0) 0 100% no-repeat,
    -webkit-linear-gradient(left, #fff 1.5px, #0288d1 0, #0288d1 3.5px, #fff 0) bottom repeat-x,
    -webkit-linear-gradient(left, #0288d1 100%, #fff 0) 100% 100% no-repeat,
    -webkit-linear-gradient(bottom, #0288d1 100%, #fff 0) 100% 0 no-repeat,
    -webkit-linear-gradient(bottom, #fff 1.5px, #0288d1 0, #0288d1 3.5px, #fff 0) 100% repeat-y,
    -webkit-linear-gradient(bottom, #0288d1 100%, #fff 0) 100% 100% no-repeat;
  background: linear-gradient(90deg, #0288d1 100%, #fff 0) 0 0 no-repeat,
    linear-gradient(90deg, #fff 1.5px, #0288d1 0, #0288d1 3.5px, #fff 0) top repeat-x,
    linear-gradient(90deg, #0288d1 100%, #fff 0) 100% 0 no-repeat,
    linear-gradient(0deg, #0288d1 100%, #fff 0) 0 0 no-repeat,
    linear-gradient(0deg, #fff 1.5px, #0288d1 0, #0288d1 3.5px, #fff 0) 0 repeat-y,
    linear-gradient(0deg, #0288d1 100%, #fff 0) 0 100% no-repeat,
    linear-gradient(90deg, #0288d1 100%, #fff 0) 0 100% no-repeat,
    linear-gradient(90deg, #fff 1.5px, #0288d1 0, #0288d1 3.5px, #fff 0) bottom repeat-x,
    linear-gradient(90deg, #0288d1 100%, #fff 0) 100% 100% no-repeat,
    linear-gradient(0deg, #0288d1 100%, #fff 0) 100% 0 no-repeat,
    linear-gradient(0deg, #fff 1.5px, #0288d1 0, #0288d1 3.5px, #fff 0) 100% repeat-y,
    linear-gradient(0deg, #0288d1 100%, #fff 0) 100% 100% no-repeat;
  background-size: 2px 1px, 5px 1px, 2px 1px, 1px 2px, 1px 5px, 1px 2px;
}
.dhx-spreadsheet-grid .dhx_custom_header_cell {
  font-weight: 400;
  height: 100%;
  color: rgba(0, 0, 0, 0.7);
}
.dhx-spreadsheet-grid .dhx_grid_footer .dhx_grid_footer_cell,
.dhx-spreadsheet-grid .dhx_grid_header_cell,
.dhx_grid_footer .dhx-spreadsheet-grid .dhx_grid_footer_cell {
  overflow: visible;
}
.dhx-spreadsheet-grid .dhx_resizer_grip {
  position: absolute;
  z-index: 100;
  top: 0;
  right: -10.5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 100%;
  cursor: col-resize;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.dhx-spreadsheet-grid .dhx_resizer_grip:hover .dhx_resizer_grip_line {
  opacity: 1;
}
.dhx-spreadsheet-grid .dhx_resizer_grip:active {
  right: -100.5px;
  width: 200px;
}
.dhx-spreadsheet-grid .dhx_resizer_grip_line {
  z-index: 100;
  width: 2px;
  height: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  pointer-events: none;
  opacity: 0;
  background: #0288d1;
}
.dhx-spreadsheet-grid .resize_line {
  position: absolute;
  width: 2px;
  background: #0288d1;
}
.dhx-spreadsheet-grid .dhx_header_fixed_cols .dhx_header_row {
  border: none;
}
.dhx-spreadsheet-grid .dhx_grid_fixed_cols .dhx_grid_cell {
  padding: 0 8px 0 0;
  text-align: right;
  letter-spacing: -0.8px;
  border: none;
  border-top: 1px solid #ededed;
  background: #ededed;
}
.dhx-spreadsheet-grid .dhx_grid_fixed_cols .dhx_selected_row .dhx_grid_cell {
  border-top: 1px solid #ccc;
  background: #dcdcdc;
}
.dhx-spreadsheet-grid .dhx_grid_fixed_cols .dhx_selected_row + .dhx_grid_row .dhx_grid_cell {
  border-top: 1px solid #ccc;
}
.dhx-spreadsheet-grid .dhx_grid_footer .dhx_selected_header + .dhx_grid_footer_cell,
.dhx-spreadsheet-grid .dhx_selected_header + .dhx_grid_header_cell,
.dhx_grid_footer .dhx-spreadsheet-grid .dhx_selected_header + .dhx_grid_footer_cell {
  border-left: 1px solid #ccc;
}
.toolbar_wrapper {
  min-height: 44px;
  background: #f7f7f7;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}
.toolbar_wrapper .toolbar {
  height: 40px;
  padding-top: 4px;
  padding-left: 0;
  background-color: #f7f7f7;
  box-shadow: none;
}
.toolbar_wrapper .menu-item .dhx-icon-block {
  margin-right: 0;
}
.toolbar_wrapper .dhx-icon .dxi {
  color: rgba(0, 0, 0, 0.7);
}
.dhx_toolbar_colorpicker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dhx_selected_color {
  width: 20px;
  height: 20px;
  border: 1px solid #dfdfdf;
}
.dhx_colorpicker_title {
  font-weight: 400;
  padding: 0 0 0 8px;
}
.dhx_format-dropdown {
  font-weight: 400;
  text-transform: capitalize;
}
.dhx_format-dropdown .menu-item-content,
.dhx_format-name-wrap {
  font-weight: 400;
}
.dhx_format-name-wrap.active {
  background: #ededed;
}
.dhx_format-name-wrap.active:before {
  content: '';
  display: block;
  width: 2px;
  height: 100%;
  background: #0288d1;
  position: absolute;
  left: 0;
  top: 0;
}
.dhx_format-name-wrap .html-content {
  width: 100%;
}
.dhx_format-helper {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  padding-left: 8px;
}
.dhx_format-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.dhx_format-name {
  text-transform: capitalize;
}
.menu_wrapper {
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}
.dhx_spreadsheet_colorpicker {
  border: 0;
  border-bottom-width: 3px;
  border-style: solid;
  margin-bottom: -3px;
}
